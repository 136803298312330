<template>
  <div class="step">
    <div class="step__header">
      <img
          class="step__img"
          :src="schoolAvatar"
          draggable="false"/>
      <div class="step__school school">
        <p class="school__name">{{profileSchool.name}}</p>
        <p class="school__class">{{cls.fullName}} {{ (profileSchool.type === 1) ? "группа" : "класс"}}</p>
      </div>
    </div>
    <p class="step__title">Карточка родителя</p>
    <p class="step__description" v-if="!isAlert">Данные проверяются в учреждении</p>
    <AlertText
        class="step__alert"
        v-if="isAlert"
        :value="alertText"/>
    <InputAuth
        class="step__input"
        id="firstName"
        type="text"
        label="ИМЯ"
        v-model="firstName"
        :is-trim-enable="false"
    />
    <InputAuth
        class="step__input"
        id="lastName"
        type="text"
        label="ФАМИЛИЯ"
        v-model="lastName"
        :is-trim-enable="false"
    />
    <InputAuth
        class="step__input"
        id="email1-input"
        type="text"
        label="EMAIL"
        v-model="email"
        :is-trim-enable="true"
    />
    <InputAuth
        class="step__input"
        type="tel"
        id="pho-input"
        label="НОМЕР ТЕЛЕФОНА"
        v-model="phone"
        :is-trim-enable="true"
    />
    <div class="step__agreement">
      <div class="step__check" @click="accept = !accept">
        <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <defs>
            <linearGradient x1="100%" y1="108.217174%" x2="0%" y2="0%" id="linearGradient-1">
              <stop stop-color="#E83F94" offset="0%"></stop>
              <stop stop-color="#F54E5E" offset="100%"></stop>
            </linearGradient>
          </defs>
          <g id="icons/памятка/галки-нет" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" v-if="!accept">
            <path d="M2.88888889,2.88888889 L23.1111111,2.88888889 L23.1111111,23.1111111 L2.88888889,23.1111111 L2.88888889,2.88888889 Z M23.1111111,0 L2.88888889,0 C1.3,0 0,1.3 0,2.88888889 L0,23.1111111 C0,24.7 1.3,26 2.88888889,26 L23.1111111,26 C24.7,26 26,24.7 26,23.1111111 L26,2.88888889 C26,1.3 24.7,0 23.1111111,0 L23.1111111,0 Z" id="Fill-93" fill="url(#linearGradient-1)"></path>
          </g>
          <g id="icons/памятка/галка-есть" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" v-else>
            <path d="M23.1111111,0 L2.88888889,0 C1.28555556,0 0,1.3 0,2.88888889 L0,23.1111111 C0,24.7 1.28555556,26 2.88888889,26 L23.1111111,26 C24.7144444,26 26,24.7 26,23.1111111 L26,2.88888889 C26,1.3 24.7144444,0 23.1111111,0 L23.1111111,0 Z M10.1111111,20.2222222 L2.88888889,13 L4.92555556,10.9633333 L10.1111111,16.1344444 L21.0744444,5.17111111 L23.1111111,7.22222222 L10.1111111,20.2222222 L10.1111111,20.2222222 Z" id="Fill-87" fill="url(#linearGradient-1)"></path>
          </g>
        </svg>
        <span :class="{'step__alert-text' : isAcceptAlert}">
              Я соглашаюсь с <a @click="goToTerms">политикой компании</a> <br>и правилами сбора и обработки <a
            @click="goToTerms">персональных данных</a>.
            </span>
      </div>
    </div>
    <ButtonPink
        class="step__button"
        @click.native="next"
        :disabled="isAlert"
        size="md"
        value="ДАЛЕЕ"/>

  </div>
</template>

<script>

import InputAuth from "../input/InputAuth";
import AlertText from "../alert/AlertText";
import ButtonPink from "../button/ButtonPink";
export default {
  name: "FirstStepContainer",
  components: {ButtonPink, AlertText, InputAuth},
  props:{
    schoolAvatar: String,
    alertText: String,
    isAlert: Boolean,
    profileSchool:{
      default: () => {
        return {}
      }
    },
    cls:{
      default: () => {
        return {}
      }
    },
  },
  watch:{
    accept:{
      handler(){
        this.$emit('normal')
      }
    },
    email(){
      this.$emit('normal')
    },
    phone(){
      this.$emit('normal')
    },
    lastName(){
      this.$emit('normal')
    },
    firstName(){
      this.$emit('normal')
    }
  },
  data(){
    return {
      email: "",
      phone: "",
      firstName: "",
      lastName: "",
      accept: false,
      isAcceptAlert: false
    }
  },

  computed:{
    isEnabled(){
      return (this.firstName.length > 0 && this.lastName.length > 0 && this.email > 0 && this.phone.length > 0)
    }
  },
  methods:{
    goToTerms() {
      window.open('https://esova.ru/terms_of_use', "_blank");
    },
    next(){
     if (!this.accept){
        this.isAcceptAlert = true

      }
      this.$emit("next",this.email,this.phone,this.firstName,this.lastName,this.accept)

    }
  }
}
</script>

<style scoped lang="scss">
.step{
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  align-items: normal;

  &__header{
    display: flex;
    margin-top: 24px;

  }

  &__img{
    width: 48px;
    height: 48px;
    border: 1px solid #E3E6EA;
    border-radius: 50%;
  }

  &__school{
    margin-left: 12px;
  }

  &__title{
    margin-top: 4px;
    font-family: CodeNext;
    font-weight: 800;
    font-size: 24px;
    color: #222222;
    line-height: 31px;
  }

  &__description{
    font-family: Roboto, "sans-serif";
    font-weight: 300;
    font-size: 14px;
    color: #333942;
    line-height: 17px;
    margin-top: 4px;
    margin-bottom: -10px;
  }

  &__alert{
    margin-bottom: -10px;
    margin-top: 10px;
  }

  &__input{
    margin-top: 30px;
  }

  &__button{
    margin-top: 45px;
    margin-bottom: 45px;
  }

  &__check{
    display: flex;
    align-items: center;
    margin-top: 21px;


    span{
      font-family: Roboto, "sans-serif";
      font-weight: 300;
      font-size: 10px;
      color: #222222;
      line-height: 14px;
      margin-left: 15px;
    }

    a{
      text-decoration: underline;
    }
  }

  &__alert-text{
    color: #F56767 !important;
  }
}




.school{
  display: flex;
  flex-direction: column;

  &__name{
    font-family: Roboto, "sans-serif";
    font-weight: 500;
    font-size: 14px;
    color: #333942;
    letter-spacing: 0;
    line-height: 16px;
  }

  &__class{
    margin-top: 6px;
    width: 98px;
    height: 26px;
    background: #F6F7F8;
    border-radius: 13px;
    font-family: Roboto, "sans-serif";
    font-weight: 400;
    vertical-align: middle;
    font-size: 13px;
    color: #788396;
    letter-spacing: 0;
    text-align: center;
    line-height: 26px;

    pointer-events: none;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }
}


</style>
