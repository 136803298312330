<template>
  <div class="container">
    <div class="modal--view" v-show="!isLoading" :class="{'modal--view_alert' : isAlert}">
      <div class="b-carousel js-carousel">
        <div class="b-carousel__wrap js-carousel__wrap">
          <div class="b-carousel__item fade-in-right">
            <first-step-container
                :alert-text="alertText"
                :is-alert="isAlert"
                :cls="cls"
                :profile-school="profileSchool"
                :school-avatar="schoolAvatar"
                @exit="exit"
                @normal="isAlert = false;alertText = ''"
                @next="next1step"></first-step-container>
          </div>
          <div class="b-carousel__item fade-out-left">
            <third-step-container
                @previous="previous2"
                @next="final"></third-step-container>
          </div>
        </div>
      </div>
      <div class="container__buttons">
        <a
            class="container__button"
            v-if="position === 0"
            @click="exit">ВЫХОД</a>
        <a
            class="container__button"
            v-if="position === 1"
            @click="previous">НАЗАД</a>
      </div>
    </div>
    <div class="auth--loader" v-if="isLoading">
      <video-loader></video-loader>
    </div>
  </div>
</template>

<script>

import VideoLoader from "@/components/loader/VideoLoader";
import FirstStepContainer from "../../components/interview/FirstStepContainer";
import ThirdStepContainer from "../../components/interview/ThirdStepContainer";
export default {
  name: 'InterviewView',
  components: {
    ThirdStepContainer, FirstStepContainer, VideoLoader},
  data(){
    return {
      privates: {},
      carousel: {},
      position: 0,
      isAlert: false,
      alertText: "",
      email: "",
      phone: "",
      lastName: "",
      firstName: "",
      profileSchool:  {},
      cls:{},
      isLoading: false
    }
  },
  computed:{
    schoolAvatar(){
      if (this.profileSchool.avatar === null)
        return ""
      return this.profileSchool.avatar
    },

  },
  methods:{
    goToTerms() {
      window.open('https://esova.ru/terms_of_use', "_blank");
    },
    next1step(email,phone,firstName,lastName,accept){
      this.email = email
      this.phone = phone
      this.firstName = firstName
      this.lastName = lastName
      // this.phone = this.phone.substr(0,16)
      if(this.email.length === 0 || !this.isValidEmail(this.email)){
        this.alertText = "E-mail введен некорректно"
        this.isAlert = true
      } else if (!this.isValidPhone(this.phone)){
        this.alertText = "Некорректный номер"
        this.isAlert = true
      } else if (this.firstName.length === 0){
        this.alertText = "Имя не должно быть пустым"
        this.isAlert = true
      } else if (this.lastName.length === 0){
        this.alertText = "Фамилия не должна быть пустым"
        this.isAlert = true
      } else if (!accept) {
        this.alertText = "Необходимо согласие с политикой сервиса"
        this.isAlert = true
      } else {
        this.isAlert = false
        this.next()
      }
    },



    final(){
      this.onUpdateUserData(this.saveInformation())
    },

    async onUpdateUserData(dataObject){
      await this.$store.dispatch("UPDATE_PARENT_INFO",{dataObject: dataObject})
      await this.$router.push({name: "ParentDashboard"})
    },

    isValidEmail(email){
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },

    isValidPhone(phone){
      return phone.length === 16
      // return phone.length !== 0
    },

    opened () {
      this.initCarousel({
        'main': '.js-carousel',
        'wrap': '.js-carousel__wrap',
        'prev': '.js-carousel__prev',
        'next': '.js-carousel__next'
      })
    },

    initCarousel(setting) {
      this.privates = {}

      /* Privates properties */
      this.privates.setting = setting

      this.privates.sel = {
        'main': document.querySelector(this.privates.setting.main),
        'wrap': document.querySelector(this.privates.setting.wrap),
        'prev': document.querySelector(this.privates.setting.prev),
        'next': document.querySelector(this.privates.setting.next)
      }

      this.privates.opt = {
        'position': 0,
        'max_position': 3
      }
    },

    next() {
      this.privates.sel.wrap.children[this.privates.opt.position].classList.add("fade-out-left")
      window.scrollTo(0,0)
      ++this.privates.opt.position
      ++this.position

      if (this.privates.opt.position >= this.privates.opt.max_position) {
        this.privates.opt.position = 0
      }

      // this.privates.sel.wrap.style['transform'] = `translateX(-${this.privates.opt.position}00%)`
      // setTimeout(() => this.privates.sel.wrap.children[this.privates.opt.position].classList.remove('hidden'), 200)

      this.privates.sel.wrap.children[this.privates.opt.position].classList.remove("fade-out-left")
      this.privates.sel.wrap.children[this.privates.opt.position].classList.add("fade-in-right")

    },

    previous() {
      this.isAlert = 0
      this.login = ""
      this.password = ""
      this.repeatPassword = ""
      this.privates.sel.wrap.children[this.privates.opt.position].classList.remove("fade-in-right")
      this.privates.sel.wrap.children[this.privates.opt.position].classList.add("fade-out-left")
      --this.privates.opt.position
      --this.position

      if (this.privates.opt.position < 0) {
        this.privates.sel.wrap.classList.add('s-notransition')
        this.privates.opt.position = this.privates.opt.max_position - 1
      }

      // this.privates.sel.wrap.style['transform'] = `translateX(-${this.privates.opt.position}00%)`
      this.privates.sel.wrap.children[this.privates.opt.position].classList.remove("fade-out-left")
      this.privates.sel.wrap.children[this.privates.opt.position].classList.add("fade-in-right")
    },

    previous2() {
      console.log("previewous2")
      this.privates.sel.wrap.children[this.privates.opt.position].classList.remove("fade-in-right")
      this.privates.sel.wrap.children[this.privates.opt.position].classList.add("fade-out-left")
      --this.privates.opt.position
      --this.position

      if (this.privates.opt.position < 0) {
        this.privates.sel.wrap.classList.add('s-notransition')
        this.privates.opt.position = this.privates.opt.max_position - 1
      }

      // this.privates.sel.wrap.style['transform'] = `translateX(-${this.privates.opt.position}00%)`
      this.privates.sel.wrap.children[this.privates.opt.position].classList.remove("fade-out-left")
      this.privates.sel.wrap.children[this.privates.opt.position].classList.add("fade-in-right")
    },

    async exit(){
      const res = await this.$store.dispatch("EXIT_FROM_ACCOUNT");
      if (res){
        localStorage.removeItem("accessToken")
        localStorage.removeItem("refreshToken")
        this.$router.push({name: "NewAuth"})
      }
    },


    saveInformation(){
      const dataObject = {
        firstName: this.firstName,
        lastName: this.lastName,
        parentStatus : "Новое",
        password : "",
        login : "",
        phone : this.phone,
        email : this.email
      }

      return dataObject;
    },
    onBeforeOpen(e){
      this.profileSchool = e.params.profileSchool
      this.cls = e.params.class
    }

  },
  async created(){
    try{
      this.isLoading = true
      await this.$store.dispatch("GET_PROFILE")
      // eslint-disable-next-line no-unused-vars
      const profile = this.$store.getters.PROFILE
      await this.$router.push({name: 'ParentDashboard'})


      this.isLoading = false
    } catch (e) {
      await this.$store.dispatch("GET_SCHOOL",{ id : e.profile.schoolId})
      const account = this.$store.getters.SCHOOL

      this.onBeforeOpen({
        params:{
          profileSchool : account,
          class : e.schoolClass
        }
      })

      this.isLoading = false

    }
  },
  mounted(){
    this.opened()

  }
}
</script>

<style scoped lang="scss">

.container{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
  flex-direction: column;
  overflow: scroll;


  &__button{
    font-family: Roboto, "sans-serif";
    font-weight: 500;
    font-size: 17px;
    color: #C2C2C2;
    text-align: center;
    cursor: pointer;
  }

  &__buttons{
    margin-top: 30px;
    margin-left: 30px;
  }
}

.modal--view{
  width: 350px;
  height: 605px;
  background: #FFFFFF;
  border: 1px solid rgba(198,206,212,0.50);
  border-radius: 4px;

  &_alert{
    height: 638px;
  }

  margin-bottom: 50px;

  @include media(992){

  }
}
.b-carousel {
  position: relative;
  height: 100%;
}

.b-carousel__prev,
.b-carousel__next {
  position: absolute;
  top: 50%;
  left: 20px;
  width: 50px;
  height: 50px;
  background: #fff;
  transform: translateY(-50%) translateZ(0);
  cursor: pointer;
  text-indent: 100%;
  white-space: nowrap;
  z-index: 3;
}

.b-carousel__next {
  left: auto;
  right: 20px;
}

.b-carousel__wrap {
  display: flex;
  transition: opacity .5s;
  will-change: opacity;
  position: relative;
  z-index: 1;
  height: auto;
}

.b-carousel__item {
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.over-hidden{
  overflow: hidden;
}

.hidden{
  display: none;
}




/* ----------------------------------------------
* Generated by Animista on 2020-7-14 14:41:35
* Licensed under FreeBSD License.
* See http://animista.net/license for more info.
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/* ----------------------------------------------
* Generated by Animista on 2020-7-14 14:43:33
* Licensed under FreeBSD License.
* See http://animista.net/license for more info.
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-out-left
 * ----------------------------------------
 */
@-webkit-keyframes fade-out-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
}
@keyframes fade-out-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
}


.fade-in-right {
  -webkit-animation: fade-in-right 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in-right 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.fade-out-left {
  display: none;
  pointer-events: none;
}




.auth--loader{
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.16);
  position: absolute;
  z-index: 1;

  div{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
  }
}

.prepare--text-container{
  width: 355px;
  padding: 0;

  opacity: .3;
  font-family: Roboto, "sans-serif";
  font-weight: 300;
  letter-spacing: 0;
  font-size: 13px;
  color: #000000;
  line-height: 17px;
  text-align: center;
  margin-top: 25px;

  a{
    cursor: pointer;
    text-decoration: underline;
  }

  @include for-mobile-devices{
    bottom: -115px;
  }

}
</style>
