<template>
  <div class="interview-fourth-step--container">
    <div class="form">
      <div class="form__container">
        <div class="form__content">
          <b>Памятка пользователя</b>
        </div>
      </div>
      <p class="form__text-mb">
        Назначение сервиса – защита прав и здоровья участников образовательного процесса,
        обеспечение сохранности имущества учреждения, ознакомление с формой и содержанием образования</p>
      <p class="form__head">Пользователь обязан:</p>
      <div class="form__text">
        <div class="form__wrap" @scroll="changeScrollText" ref="scrollText">
          <ul>
            <li>
              Не передавать ключи доступа посторонним! Использование сервиса, помимо работников образования, допустимо родителями (законными представителями) обучающихся
            </li>
            <li>
              Не создавать копии любыми способами! В случае необходимости нужный фрагмент архива выдается по запросу, либо организуется коллективный просмотр в учреждении
            </li>
            <li>
              Не распространять фото видео материалы, полученные в сервисе, в том числе в сети Интернет!
            </li>
            <li>
              Не допускать вмешательства в образовательный процесс на основании информации, полученной в сервисе! Не предпринимать попыток корректирования профессиональной деятельности педагогов!
            </li>
            <li>
              Не использовать сервис для идентификации изображенных на видео людей без их письменного согласия!
            </li>
          </ul>
        </div>
        <div class="form__arrow" v-if="!scrollText">
          <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.5 0C4.77614 0 5 0.223858 5 0.5V2H7C7.82405 2 8.29443 2.94076 7.8 3.6L5.3 6.93333C4.9 7.46667 4.1 7.46667 3.7 6.93333L1.2 3.6C0.705573 2.94076 1.17595 2 2 2H4V0.5C4 0.223858 4.22386 0 4.5 0V0Z" fill="#979797"/>
          </svg>
        </div>
      </div>
      <div class="form__info">
        В случае неоднократного нарушения правил, сервис оставляет
        за собой право на прекращение предоставления услуг без объяснения причин.
      </div>
      <div class="form__buttons">
        <ButtonPink
            class="form__button"
            size="md"
            value="ВОЙТИ"
            @click.native="$emit('next')"/>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonPink from "../button/ButtonPink";
export default {
  name: "ThirdStepContainer",
  components: {ButtonPink},
  data() {
    return {
      scrollText: 0,
      accept: false
    }
  },
  methods: {
    goToTerms() {
      window.open('https://esova.ru/terms_of_use', "_blank");
    },
    changeScrollText() {
      const top = this.$refs.scrollText.scrollTop
      this.scrollText = top
    }
  }
}
</script>

<style scoped lang="scss">

.interview-fourth-step--container{

  .interview--container h1{
    margin-top: 57px;
  }


  .form{
    margin-top: 39px;
    margin-left: 15px;
    margin-right: 15px;

    &__container{
      display: flex;
    }

    &__content{
      margin-left: 14px;
      b{
        font-family: CodeNext;
        font-weight: 800;
        font-size: 24px;
        color: #222222;
        line-height: 31px;


      }
    }

    &__text-mb{
      font-family: Roboto, "sans-serif";
      font-weight: 300;
      font-size: 12px;
      color: #333942;
      line-height: 18px;
      margin-left: 14px;
      letter-spacing: 0;

    }

    &__head{
      font-family: Roboto, "sans-serif";
      font-weight: 500;
      font-size: 12px;
      color: #333942;
      line-height: 18px;
      margin-top: 14px;
      margin-left: 15px;
    }

    &__text{

      margin-top: 5px;
      position: relative;
      width: 320px;
      height: 260px;


      background: #F6F7F8;
      border: 1px solid #E3E6EA;
      border-radius: 2px;
    }

    &__wrap{
      padding-right: 23px;
      overflow: auto;
      height: 250px;

      &::-webkit-scrollbar {
        display: none;
      }


      ul{
        list-style-type: none;
        margin-left: 8px;
        margin-top: 8px;

        li {
          padding-left: 14px;
          margin-bottom: 15px;
          position: relative;
          font-style: normal;


          font-family: Roboto, "sans-serif";
          font-weight: 100;
          font-size: 12px;
          color: #000000;
          line-height: 17px;
          /* or 142% */



          &:last-child {
            margin-bottom: 0;
          }
          &::before {
            content: '!';
            position: absolute;
            top: 0;
            left: 0;

            font-family: Roboto, sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 17px;
            line-height: 20px;
            /* identical to box height */

            text-align: center;


            color: #CF6868;
          }
        }
      }
    }
    &__arrow {
      position: absolute;
      right: 8px;
      bottom: 6px;
      cursor: pointer;
      animation: opacityArrow 1s ease infinite;
    }
    &__info{
      opacity: 0.7;
      font-family: Roboto, "sans-serif";
      font-weight: 300;
      font-size: 10px;
      color: #000000;
      letter-spacing: 0;
      line-height: 13px;
      margin-top: 12px;
      margin-left: 15px;

    }
    &__footer {
      padding-top: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @include for-mobile-devices {
        padding-top: 15px;
      }
      span {
        font-family: Roboto, sans-serif;
        padding-left: 10px;
        font-style: normal;
        font-weight: 300;
        font-size: 10px;
        line-height: 14px;
        /* or 140% */


        color: #000000;
        max-width: 250px;
        a {
          color: #000;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    &__check {
      cursor: pointer;
    }
    &__buttons {
      margin-top: 34px;
      margin-left: 15px;

    }
  }
}

@keyframes opacityArrow {
  from {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  to {
    opacity: 1;
  }
}
</style>
